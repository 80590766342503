import { useEffect, useMemo, useState } from "react";
import FlexiDataTable from "@/components/FlexiDataTable";
import { currencyRender, DTColProps, ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { ComponentType, SUCCESS_FAILED } from "@/constants";
import { isEmptyOrNull } from "@/utils/string";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { CASymbolData, CASymbolWinLossData, ClientAnalysisSymbolByLogin, FlexiDataTableOptionsProps } from "@/constants/type";
import { Table, Typography } from "antd";

interface SymbolDataProps {
    server?: string;
    login?: number | string;
    isInitialLoading: boolean;
};

interface SymbolDataState extends CASymbolData {
    symbolLossData: CASymbolWinLossData;
    symbolWinData: CASymbolWinLossData;
};

const SymbolData = (props: SymbolDataProps) => {
    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(props.isInitialLoading);
    const [symbols, setSymbols] = useState<string[]>([]);
    const [expandRowKeys, setExpandRowKeys] = useState<string[]>([]);

    const columns: any[] = [
        DTColProps.Small({
            title: "Symbol",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
            options: {
                filter: {
                    type: ComponentType.dropdown,
                    value: symbols.map((x: string) => ({ text: x, value: x })),
                    // inputProps: {
                    //     mode: "multiple",
                    // },
                },
            },
        }, ["text-center"]),
        DTColProps.SCurrency({
            title: "Lots",
            dataIndex: "lots",
            key: "lots",
            sorter: (a: any, b: any) => a.lots - b.lots,
        }),
        DTColProps.SCurrency({
            title: "PNL",
            dataIndex: "pnl",
            key: "pnl",
            sorter: (a: any, b: any) => a.pnl - b.pnl,
        }),
        DTColProps.SCurrency({
            title: "Avg Pip",
            dataIndex: "avgPip",
            key: "avgPip",
            sorter: (a: any, b: any) => a.avgPip - b.avgPip,
        }),
        DTColProps.SCurrency(
            {
                title: "Count Ticket",
                dataIndex: "countTicket",
                key: "countTicket",
                sorter: (a: any, b: any) => a.countTicket - b.countTicket,
            },
            [],
            -1
        ),
        DTColProps.SCurrency({
            title: "Avg Volume",
            dataIndex: "avgVolume",
            key: "name",
            sorter: (a: any, b: any) => a.avgVolume - b.avgVolume,
        }),
    ];

    const displaySummaryTotal = useMemo(() => {
        let totalData = data.length,
            totalLots = 0,
            totalPnl = 0,
            totalAvgPip = 0,
            totalCountTicket = 0,
            totalAvgVolume = 0,
            finalTotalAvgPip = 0,
            finalTotalAvgVolume = 0;

        data.forEach((x: SymbolDataState) => {
            totalLots += x.lots;
            totalPnl += x.pnl;
            totalAvgPip += x.avgPip;
            totalCountTicket += x.countTicket;
            totalAvgVolume += x.avgVolume;
            finalTotalAvgPip = totalAvgPip / totalData;
            finalTotalAvgVolume = totalAvgVolume / totalData;
        });

        return (
            <>
                <Table.Summary fixed>
                    <Table.Summary.Row className="summary-row">
                        <Table.Summary.Cell index={0} align="center">Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="right">
                            <Typography.Text>{currencyRender(totalLots, 2)}</Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="right">
                            <Typography.Text type={totalPnl < 0 ? "danger" : undefined}>
                                {currencyRender(totalPnl, 2)}
                            </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} align="right">
                            <Typography.Text type={finalTotalAvgPip < 0 ? "danger" : undefined}>
                                {currencyRender(finalTotalAvgPip, 2)}
                            </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} align="right">
                            <Typography.Text type={totalCountTicket < 0 ? "danger" : undefined}>
                                {currencyRender(totalCountTicket, -1)}
                            </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} align="right">
                            <Typography.Text type={finalTotalAvgVolume < 0 ? "danger" : undefined}>
                                {currencyRender(finalTotalAvgVolume, 2)}
                            </Typography.Text>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                </Table.Summary>
            </>
        );
    }, [data]);

    const options: FlexiDataTableOptionsProps = useMemo(() => {
        return {
            defaultCollapseFilterPanel: true,
            expandable: {
                // expandedRowRender: getExpandedRowRender,
                // expandedRowKeys: expandRowKeys,
                defaultExpandAllRows: true,
                onExpand: (expanded: boolean, expandedRows: any) => {
                    if (expanded) {
                        setExpandRowKeys([...expandRowKeys, expandedRows.key])
                    } else {
                        const index = expandRowKeys.findIndex(e => e === expandedRows.key);
                        const newExpandRowKeys = [...expandRowKeys];
                        newExpandRowKeys.splice(index, 1);
                        setExpandRowKeys(newExpandRowKeys);
                    };
                },
            },
            summary: () => displaySummaryTotal,
        };
    }, [displaySummaryTotal, expandRowKeys]);

    useEffect(() => {
        setIsLoading(true);
        const formData = new FormData();
        if (props && !isEmptyOrNull(props.login) && !isEmptyOrNull(props.server)) {
            formData.append("oldServer", props.server as string);
            formData.append("login", props.login ? props.login.toString() : "");
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_SYMBOLS_BY_LOGIN}`, formData)
                .then((res: any) => {
                    if (res.data.length === 0) setData([]);
                    else {
                        let expandedRowKeyArr: any[] = [];
                        let finalArr: any[] = [];
                        res.data.forEach((x: ClientAnalysisSymbolByLogin, idx: number) => {
                            let sub1Arr: any[] = [];
                            if (x.symbolWinData.name !== null) {
                                let childrenItem: any[] = [];
                                x.symbolWinData.symbolDataDetails.forEach((currSymbolWinDetail: CASymbolData, winIdx: number) => {
                                    childrenItem.push({
                                        ...currSymbolWinDetail,
                                        key: `${idx}|win|${winIdx}`,
                                    });
                                });
                                sub1Arr.push({
                                    key: idx + "|win",
                                    ...x.symbolWinData,
                                    children: childrenItem,
                                });
                                expandedRowKeyArr.push(idx + "|win");
                            };
                            if (x.symbolLossData.name !== null) {
                                let childrenItem: any[] = [];
                                x.symbolLossData.symbolDataDetails.forEach((currSymbolLossDetail: CASymbolData, lossIdx: number) => {
                                    childrenItem.push({
                                        ...currSymbolLossDetail,
                                        key: `${idx}|loss|${lossIdx}`,
                                    });
                                });
                                sub1Arr.push({
                                    key: idx + "|loss",
                                    ...x.symbolLossData,
                                    children: childrenItem,
                                });
                                expandedRowKeyArr.push(idx + "|loss");
                            };
                            finalArr.push({
                                key: idx,
                                ...x.symbolData,
                                children: sub1Arr,
                            });
                            expandedRowKeyArr.push(idx);
                        });
                        let symbolsList = finalArr.map((x: any) => x.name)
                            .sort((a: string, b: string) => a.localeCompare(b));
                        setData(finalArr);
                        setExpandRowKeys(expandedRowKeyArr);
                        setSymbols(symbolsList);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("symbol data", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                }))
                .finally(() => setIsLoading(false));
        } else setIsLoading(false);
        return () => {
            setIsLoading(false);
        };
    }, [props.server, props.login]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Symbol Data</span>
            </div>
            <div>
                <FlexiDataTable
                    bordered
                    rowKeyProperty="key"
                    title={false}
                    columns={columns}
                    options={options}
                    dataSource={data ?? []}
                    callback={() => { }}
                    loading={isLoading}
                    scroll={{ y: 500 }}
                // pagination={false}
                />
                {/* <Table
                    columns={columns}
                    loading={isLoading}
                    dataSource={data}
                    pagination={false}
                    size={`small`}
                    sticky={true}
                    scroll={{ y: 500 }}
                    expandable={{
                        expandedRowKeys: expandRowKeys,
                        onExpand: (expanded, expandedRows) => {
                            if (expanded) {
                                setExpandRowKeys([...expandRowKeys, expandedRows.key])
                            } else {
                                const index = expandRowKeys.findIndex(e => e === expandedRows.key);
                                const newExpandRowKeys = [...expandRowKeys];
                                newExpandRowKeys.splice(index, 1);
                                setExpandRowKeys(newExpandRowKeys);
                            }
                        },
                    }}
                    summary={() => displaySummaryTotal}
                /> */}
            </div>
        </div>
    );
};

export default SymbolData;