import { TIMEZONE_FORMATS, TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM, TOXIC_CLIENT_CHECK_ENTRY_STATUS, TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM } from "@/constants";
import { accountRestrictionsAuditLog, AttributeProps, BrandsList, clientRestrictionsAuditLog, CrmBrandList, entryPropsAuditLog, labelAttrsAuditLog, RegulatorList, TccCommonFilterSourceList, TccCommonFilterLabelList, ToxicClientRestrictions, ToxicClientSourceEventProps, UsersList } from "@/constants/type";
import { DTColProps } from "@/utils/Common";
import { isEmptyOrNull } from "@/utils/string";
import { ArrowRightOutlined } from "@ant-design/icons";

interface ValueChangesColsProps {
    users: UsersList[];
    tcLabelProps: TccCommonFilterLabelList[];
    tcLabelAttrs: AttributeProps[];
    tcRestrictions: ToxicClientRestrictions[];
    brands: BrandsList[];
    crmBrands: CrmBrandList[];
    regulators: RegulatorList[];
    tcSources: TccCommonFilterSourceList[];
};

const valueChangesCols = (props: ValueChangesColsProps) => {
    return [
        DTColProps.XXSmall({
            title: "Row Number",
            dataIndex: "rowNum",
            key: "rowNum",
        }),
        DTColProps.Middle({
            title: "General",
            dataIndex: "entryProps",
            key: "entryProps",
            render: (entryProps: entryPropsAuditLog[]) => {
                return entryProps.map((currEntryProp: entryPropsAuditLog, i: number) => {
                    let compareContent: React.ReactNode = <></>;
                    switch (currEntryProp.propName) {
                        case "Symbols":
                            compareContent = (
                                <>
                                    <span className="before">
                                        {isEmptyOrNull(currEntryProp.before)
                                            ? "No value"
                                            : currEntryProp.before.length > 0
                                                ? currEntryProp.before.join(", ")
                                                : "No value"
                                        } <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {isEmptyOrNull(currEntryProp.after)
                                        ? "No value"
                                        : currEntryProp.after.length > 0
                                            ? currEntryProp.after.join(", ")
                                            : "No value"
                                    }
                                </>
                            );
                            break;
                        case "Status":
                            compareContent = (
                                <>
                                    <span className="before">
                                        {TOXIC_CLIENT_CHECK_ENTRY_STATUS[currEntryProp.before]} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {TOXIC_CLIENT_CHECK_ENTRY_STATUS[currEntryProp.after]}
                                </>
                            );
                            break;
                        case "BrandId":
                            let beforeBrand = props.brands.find((b: BrandsList) => b.id === currEntryProp.before);
                            let afterBrand = props.brands.find((b: BrandsList) => b.id === currEntryProp.after);
                            compareContent = (
                                <>
                                    <span className="before">
                                        {isEmptyOrNull(beforeBrand) ? "-" : beforeBrand?.brand} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {isEmptyOrNull(afterBrand) ? "-" : afterBrand?.brand}
                                </>
                            );
                            break;
                        case "CrmBrandId":
                            let beforeCrmBrand = props.crmBrands.find((x: CrmBrandList) => x.id === currEntryProp.before);
                            let afterCrmBrand = props.crmBrands.find((x: CrmBrandList) => x.id === currEntryProp.after);
                            compareContent = (
                                <>
                                    <span className="before">
                                        {isEmptyOrNull(beforeCrmBrand) ? "-" : beforeCrmBrand?.brand} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {isEmptyOrNull(afterBrand) ? "-" : afterCrmBrand?.brand}
                                </>
                            );
                            break;
                        case "RegulatorId":
                            let beforeRegulator = props.regulators.find((x: RegulatorList) => x.id === currEntryProp.before);
                            let afterRegulator = props.regulators.find((x: RegulatorList) => x.id === currEntryProp.after);
                            compareContent = (
                                <>
                                    <span className="before">
                                        {(isEmptyOrNull(beforeRegulator) || currEntryProp.before === 0) ? "No value" : beforeRegulator?.name} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {(isEmptyOrNull(afterRegulator) || currEntryProp.after === 0) ? "No value" : afterRegulator?.name}
                                </>
                            );
                            break;
                        case "Inspector":
                            let beforeUser = props.users.find((x: UsersList) => x.id === currEntryProp.before);
                            let afterUser = props.users.find((x: UsersList) => x.id === currEntryProp.after);
                            compareContent = (
                                <>
                                    <span className="before">
                                        {isEmptyOrNull(beforeUser) ? "-" : `${beforeUser?.name} | ${beforeUser?.email}`} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {isEmptyOrNull(afterUser) ? "-" : `${afterUser?.name} | ${afterUser?.email}`}
                                </>
                            );
                            break;
                        case "PickupTeam":
                            compareContent = (
                                <>
                                    <span className="before">
                                        {TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[currEntryProp.before]} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[currEntryProp.after]}
                                </>
                            );
                            break;
                        case "SourceId":
                            let beforeSource = props.tcSources.find((x: TccCommonFilterSourceList) => x.sourceId === currEntryProp.before);
                            let afterSource = props.tcSources.find((x: TccCommonFilterSourceList) => x.sourceId === currEntryProp.after);
                            compareContent = (
                                <>
                                    <span className="before">
                                        {(isEmptyOrNull(beforeSource) || currEntryProp.before === 0) ? "No value" : beforeSource?.sourceName} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {(isEmptyOrNull(afterSource) || currEntryProp.after === 0) ? "No value" : afterSource?.sourceName}
                                </>
                            );
                            break;
                        case "LabelId":
                            let beforeLabel = props.tcLabelProps.find((x: TccCommonFilterLabelList) => x.labelId === currEntryProp.before);
                            let afterLabel = props.tcLabelProps.find((x: TccCommonFilterLabelList) => x.labelId === currEntryProp.after);
                            compareContent = (
                                <>
                                    <span className="before">
                                        {isEmptyOrNull(beforeLabel) ? currEntryProp.before : beforeLabel?.labelName} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {isEmptyOrNull(afterLabel) ? currEntryProp.after : afterLabel?.labelName}
                                </>
                            );
                            break;
                        default:
                            compareContent = (
                                <>
                                    <span className="before">
                                        {isEmptyOrNull(currEntryProp.before) ? "No value" : currEntryProp.before} <>&nbsp;</>
                                    </span>
                                    <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                                    {isEmptyOrNull(currEntryProp.after) ? "No value" : currEntryProp.after}
                                </>
                            );
                            break;
                    };
                    return (
                        <div key={`entry-${i}`}>
                            <span className="display-name">
                                {currEntryProp.displayName}: <>&nbsp;</>
                            </span>
                            {compareContent}
                        </div>
                    );
                });
            },
        }),
        DTColProps.Middle({
            title: "Label Attribute(s)",
            dataIndex: "labelAttrs",
            key: "labelAttrs",
            render: (labelAttrs: labelAttrsAuditLog[]) => {
                return labelAttrs.map((currLabelAttr: labelAttrsAuditLog, i: number) => {
                    let currAttr = props.tcLabelAttrs.find((y: AttributeProps) => y.attrId === currLabelAttr.attrId);
                    return (
                        <div key={`labelAttr-${i}`}>
                            <span className="display-name">
                                {currAttr === undefined
                                    ? currLabelAttr.attrId
                                    : (currAttr.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DatePicker || currAttr.uiElementType === TOXIC_CLIENT_LABEL_UI_ELEMENT_TYPE_ENUM.DateTimePicker)
                                        ? `${currAttr.attrName} (${currAttr.timeZoneFormat && TIMEZONE_FORMATS[currAttr.timeZoneFormat]})`
                                        : currAttr.attrName
                                }: <>&nbsp;</>
                            </span>
                            <span className="before">
                                {isEmptyOrNull(currLabelAttr.before) ? "No value" : currLabelAttr.before} <>&nbsp;</>
                            </span>
                            <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                            {isEmptyOrNull(currLabelAttr.after) ? "No value" : currLabelAttr.after}
                        </div>
                    )
                });
            },
        }),
        DTColProps.Small({
            title: "Account Restriction(s)",
            dataIndex: "accountRestrictions",
            key: "accountRestrictions",
            render: (accountRestrictions: accountRestrictionsAuditLog[]) => {
                return accountRestrictions.map((currAccRes: accountRestrictionsAuditLog, i: number) => {
                    let thisRestriction = props.tcRestrictions.find((x: ToxicClientRestrictions) => x.restrictionCode === currAccRes.restrictionCode);
                    return (
                        <div key={`accRes-${i}`}>
                            <span className="display-name">
                                {currAccRes.restrictionCode ? thisRestriction && thisRestriction.restrictionName : currAccRes.restrictionCode}: <>&nbsp;</>
                            </span>
                            <span className="before">
                                {isEmptyOrNull(currAccRes.before) ? "No value" : currAccRes.before} <>&nbsp;</>
                            </span>
                            <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                            {isEmptyOrNull(currAccRes.after) ? "No value" : currAccRes.after}
                        </div>
                    );
                });
            },
        }),
        DTColProps.Small({
            title: "Client Restriction(s)",
            dataIndex: "clientRestrictions",
            key: "clientRestrictions",
            render: (clientRestrictions: clientRestrictionsAuditLog[]) => {
                return clientRestrictions.map((currCliRes: clientRestrictionsAuditLog, i: number) => {
                    let thisRestriction = props.tcRestrictions.find((x: ToxicClientRestrictions) => x.restrictionCode === currCliRes.restrictionCode);
                    return (
                        <div key={`cliRes-${i}`}>
                            <span className="display-name">
                                {currCliRes.restrictionCode ? thisRestriction && thisRestriction.restrictionName : currCliRes.restrictionCode}: <>&nbsp;</>
                            </span>
                            <span className="before">
                                {isEmptyOrNull(currCliRes.before) ? "No value" : currCliRes.before} <>&nbsp;</>
                            </span>
                            <ArrowRightOutlined style={{ fontSize: 12 }} /> <>&nbsp;</>
                            {isEmptyOrNull(currCliRes.after) ? "No value" : currCliRes.after}
                        </div>
                    );
                });
            },
        }),
    ];
};

export default valueChangesCols;