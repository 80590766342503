import { useMemo } from "react";
import { ClientAnalysisPnlData } from "@/constants/type";
import CustomSkeleton from "@/components/Common/Skeleton";
import EmptyData from "@/components/Common/Empty";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
]);

interface FloatingPnlChartProps {
    data: ClientAnalysisPnlData[];
    currency: string | undefined;
    isLoading: boolean;
};

const FloatingPnlChart = (props: FloatingPnlChartProps) => {
    const data = useMemo(() => {
        if (props.data.length === 0) return [];
        else {
            return props.data.map((x: ClientAnalysisPnlData) => {
                return {
                    dateYMD: x.dateYMD,
                    value: x.openProfit,
                    name: "Floating PNL"
                };
            });
        };
    }, [props.data]);

    const options: any = {
        // title: {
        //     text: "Floating PNL",
        // },
        grid: {
            left: "1%",
            containLabel: true,
        },
        // legend: {
        //     left: "left",
        // },
        tooltip: {
            trigger: "axis",
        },
        toolbox: {
            show: true,
            itemSize: 20,
            iconStyle: {
                color: "#fff",
            },
            feature: {
                show: true,
                dataView: { show: true, readOnly: false },
                saveAsImage: {
                    show: true,
                    title: "Save Image As",
                },
            },
            // right: "10%",
        },
        xAxis: [
            {
                name: "Date",
                show: true,
                type: "category",
                // splitLine: { show: false },  // to remove grid line
                data: data.map((x: any) => x.dateYMD),
                axisTick: {
                    alignWithLabel: true,
                    length: 4,
                },
            },
        ],
        yAxis: {
            type: "value",
            //show: true,
            name: `PNL (${props.currency})`,
            splitArea: { show: true },
        },
        dataZoom: [
            {
                type: "inside",
                start: 0,
                end: 100,
            },
            {
                show: true,
                type: "slider",
                start: 0,
                end: 100,
            },
            {
                textStyle: {
                    color: "#fff",
                },
            },
        ],
        series: [
            {
                name: "Floating PNL",
                type: "line",
                symbol: "none",
                color: "#FDA233",
                lineStyle: {
                    color: "#FDA233",
                },
                label: { show: true },
                emphasis: { label: { show: true } },
                data: data.map((x: any) => x.value),
            },
        ],
    }

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Floating PNL</span>
            </div>
            {props.isLoading ? (
                <CustomSkeleton rows={7} />
            ) : data.length > 0 ? (
                <ReactEChartsCore
                    echarts={echarts}
                    option={options}
                    showLoading={props.isLoading}
                    notMerge={true}
                    style={{ minHeight: 400 }}
                />
            ) : (
                <>
                    <EmptyData />
                </>
            )}
        </div>
    );
};

export default FloatingPnlChart;