import { useMemo } from "react";
import { ClientAnalysisPnlData } from "@/constants/type";
import CustomSkeleton from "@/components/Common/Skeleton";
import EmptyData from "@/components/Common/Empty";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import { LineChart, BarChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    LineChart,
    BarChart,
    CanvasRenderer,
]);

interface RealizedPnlChartProps {
    data: ClientAnalysisPnlData[];
    currency: string | undefined;
    isLoading: boolean;
};

const RealizedPnlChart = (props: RealizedPnlChartProps) => {
    const options: any = useMemo(() => {
        let dates: string[] = [];
        let closeProfits: number[] = [];
        let deposits: number[] = [];
        let withdraws: number[] = [];
        props.data.forEach((item: ClientAnalysisPnlData) => {
            dates.push(item.dateYMD);
            closeProfits.push(item.closeProfit);
            deposits.push(item.deposit);
            withdraws.push(item.withdraw);
        });

        // Let the two y-axes be aligned and start drawing from the 0 horizontal line
        let max1 = (closeProfits.length > 0) ? Math.max(...closeProfits) : 0;
        let min1 = (closeProfits.length > 0) ? Math.min(...closeProfits) : 0;
        let max2 = (deposits.length > 0) ? Math.max(...deposits) : 0;
        let min2 = (withdraws.length > 0) ? Math.min(...withdraws) : 0;
        let y1Max, y1Min, y2Max, y2Min = 0;
        let ratio = 1;

        if ((max1 - min1) !== 0 && (max2 - min2) !== 0) {
            ratio = (max1 - min1) / (max2 - min2);

            if (max1 < max2 * ratio) {
                y1Max = max2 * ratio;
                y2Max = max2;
            } else {
                y1Max = max1;
                y2Max = max1 / ratio;
            }

            if (min1 < min2 * ratio) {
                y1Min = min1;
                y2Min = min1 / ratio;
            } else {
                y1Min = min2 * ratio;
                y2Min = min2;
            }
        } else {
            y1Max = max1;
            y1Min = min1;
            y2Max = max2;
            y2Min = min2;
        }

        return {
            // title: {
            //     text: "Realized PNL",
            // },
            grid: {
                left: "1%",
                containLabel: true,
            },
            tooltip: {
                trigger: "axis",
            },
            legend: {
                left: "left",
            },
            toolbox: {
                show: true,
                itemSize: 20,
                iconStyle: {
                    color: "#fff",
                },
                feature: {
                    show: true,
                    dataView: { show: true, readOnly: false },
                    saveAsImage: {
                        show: true,
                        title: "Save Image As",
                    },
                },
                // right: "10%",
            },
            xAxis: [
                {
                    name: "Date",
                    show: true,
                    type: "category",
                    // splitLine: { show: false }, // to remove grid line
                    data: dates,
                    axisTick: {
                        alignWithLabel: true,
                        length: 4,
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    show: true,
                    name: `PNL (${props.currency})`,
                    // Multiply by 1.2 so that the highest and lowest points do not touch the boundary
                    max: (y1Max * 1.2).toFixed(0),
                    min: (y1Min * 1.2).toFixed(0),
                    splitArea: { show: true },
                },
                {
                    type: "value",
                    show: true,
                    name: "Deposit & Withdraw",
                    // Multiply by 1.2 so that the highest and lowest points do not touch the boundary
                    max: (y2Max * 1.2).toFixed(0),
                    min: (y2Min * 1.2).toFixed(0),
                    splitArea: { show: true },
                },
            ],
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                },
                {
                    show: true,
                    type: "slider",
                    start: 0,
                    end: 100,
                },
                {
                    textStyle: {
                        color: "#fff",
                    },
                },
            ],
            series: [
                {
                    name: "Realized PNL",
                    type: "line",
                    symbol: "none",
                    color: "#FDA233",
                    lineStyle: {
                        color: "#FDA233",
                    },
                    label: { show: true },
                    emphasis: { label: { show: true } },
                    data: closeProfits,
                },
                {
                    name: "Deposit",
                    type: "bar",
                    stack: "two",
                    symbol: "none",
                    color: "#49d872",
                    yAxisIndex: 1,
                    smooth: true,
                    axisLabel: {
                        formatter: "{value}",
                    },
                    data: deposits,
                },
                {
                    name: "Withdraw",
                    type: "bar",
                    stack: "two",
                    symbol: "none",
                    color: "#ff6f8a",
                    yAxisIndex: 1,
                    smooth: true,
                    axisLabel: {
                        formatter: "{value}",
                    },
                    data: withdraws,
                },
            ],
        };
    }, [props.data, props.currency]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Realized PNL</span>
            </div>
            {props.isLoading ? (
                <CustomSkeleton rows={7} />
            ) : props.data?.length > 0 ? (
                <ReactEChartsCore
                    echarts={echarts}
                    option={options}
                    showLoading={props.isLoading}
                    notMerge={true}
                    style={{ minHeight: 400 }}
                />
            ) : (
                <>
                    <EmptyData />
                </>
            )}
        </div>
    );
};

export default RealizedPnlChart;