import { useEffect, useState } from "react";
import { ClientAnalysisSymbolPnlByLogin } from "@/constants/type";
import CustomSkeleton from "@/components/Common/Skeleton";
import EmptyData from "@/components/Common/Empty";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { isEmptyOrNull } from "@/utils/string";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

interface SymbolPnlChartProps {
    server?: string;
    login?: number | string;
    isInitialLoading: boolean;
};

const SymbolPnlChart = (props: SymbolPnlChartProps) => {
    const [data, setData] = useState<ClientAnalysisSymbolPnlByLogin[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(props.isInitialLoading);

    const options: any = {
        // title: {
        //     text: "Symbol PNL",
        // },
        grid: {
            left: "1%",
            bottom: "10%",
            containLabel: true,
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        toolbox: {
            show: true,
            itemSize: 20,
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: {
                    show: true,
                    type: ["line", "bar"],
                },
                saveAsImage: { show: true },
            },
        },
        dataZoom: [
            {
                show: true,
                start: 0,
                end: 100,
            },
        ],
        xAxis: {
            type: "category",
            data: data.map((x: any) => x.symbol),
            show: false,
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                data: data.map((x: any) => x.pnl),
                type: "bar",
                label: {
                    show: true,
                    formatter: "{b}",
                    position: "bottom",
                    verticalAlign: "middle",
                    align: "center",
                    rotate: 90,
                },
            }
        ]
    };

    useEffect(() => {
        setIsLoading(true);
        const formData = new FormData();
        if (props && !isEmptyOrNull(props.login) && !isEmptyOrNull(props.server)) {
            formData.append("oldServer", props.server as string);
            formData.append("login", props.login ? props.login.toString() : "");
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_EACH_SYMBOLS_PNL_BY_LOGIN}`, formData)
                .then((res: any) => {
                    if (res.data.length === 0) setData([]);
                    else {
                        let newData = res.data.map((x: ClientAnalysisSymbolPnlByLogin) => ({
                            ...x,
                            pnl: parseFloat(x.pnl.toFixed(2)),
                        }))
                            .sort((a: any, b: any) => b.pnl - a.pnl);
                        setData(newData);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("symbol pnl chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                }))
                .finally(() => setIsLoading(false));
        } else setIsLoading(false);
        return () => {
            setIsLoading(false);
        };
    }, [props.server, props.login]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Symbol PNL</span>
            </div>
            {isLoading ? (
                <CustomSkeleton rows={7} />
            ) : data.length > 0
                ? (
                    <>
                        <ReactEChartsCore
                            echarts={echarts}
                            option={options}
                            showLoading={isLoading}
                            notMerge={true}
                            style={{ minHeight: 400 }}
                        />
                    </>
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default SymbolPnlChart;