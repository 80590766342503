import { useMemo } from "react";
import EmptyData from "@/components/Common/Empty";
import CustomSkeleton from "@/components/Common/Skeleton";
import { ClientAnalysisTradeTimeData } from "@/constants/type";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

interface TradeTimePieProps {
    data: ClientAnalysisTradeTimeData[];
    isLoading: boolean;
};

const TradeTimePie = (props: TradeTimePieProps) => {
    const options = useMemo(() => {
        let data = props.data.map((x: ClientAnalysisTradeTimeData) => ({
            name: x.openType,
            value: x.totalCount,
        }));
        return {
            // title: {
            //     text: "Sessions",
            // },
            tooltip: {
                trigger: "item",
                formatter: function (params: any) {
                    var datas =
                        params.marker +
                        params.data.name +
                        " : " +
                        params.data.value +
                        " (" +
                        params.percent +
                        "%) ";
                    if (params.data.name === "Asia") {
                        datas =
                            datas +
                            "<br>Taipei time: 05:00~15:00";
                    } else if (
                        params.data.name === "EU" ||
                        params.data.name === "Europe"
                    ) {
                        datas =
                            datas +
                            "<br>Taipei time: 15:00~21:00";
                    } else if (params.data.name === "US") {
                        datas =
                            datas +
                            "<br>Taipei time: 21:00~05:00";
                    }
                    return datas;
                },
            },
            toolbox: {
                show: true,
                itemSize: 20,
                feature: {
                    dataView: { show: true, readOnly: false },
                    saveAsImage: {
                        show: true,
                        title: "Save Image",
                    },
                },
            },
            series: [
                {
                    name: "Sessions",
                    type: "pie",
                    radius: "55%",
                    center: ["50%", "50%"],
                    label: {
                        show: true,
                        formatter: "{b}: {c} ({d}%)",
                    },
                    labelLine: {
                        show: true,
                    },
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                    data: data,
                },
            ],
        };
    }, [props.data]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Sessions</span>
            </div>
            {props.isLoading ? (
                <CustomSkeleton rows={7} />
            ) : props.data.length > 0
                ? (
                    <ReactEChartsCore
                        echarts={echarts}
                        option={options}
                        showLoading={props.isLoading}
                        notMerge={true}
                        style={{ minHeight: 400 }}
                    />
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default TradeTimePie;