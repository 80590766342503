import { useEffect, useMemo, useState } from "react";
import { Col, Row } from "antd";
import FloatingPnlChart from "./PnlTabComponents/FloatingPnlChart";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import { ClientAnalysisMarginLevelData, ClientAnalysisParams, ClientAnalysisPnlData } from "@/constants/type";
import { isEmptyOrNull } from "@/utils/string";
import RealizedPnlChart from "./PnlTabComponents/RealizedPnlChart";
import PnlChart from "./PnlTabComponents/PnlChart";
import DpmTrendChart from "./PnlTabComponents/DpmTrendChart";
import MarginLevelChart from "./PnlTabComponents/MarginLevelChart";

interface PnlTabProps {
    params: ClientAnalysisParams,
    isInitialLoading: boolean;
};

const PnlTab = (props: PnlTabProps) => {
    const [pnlData, setPnlData] = useState<ClientAnalysisPnlData[]>([]);
    const [marginLevelData, setMarginLevelData] = useState<ClientAnalysisMarginLevelData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(props.isInitialLoading);
    const [isMlcLoading, setIsMlcLoading] = useState<boolean>(props.isInitialLoading);

    const params = useMemo(() => {
        let tmpArr = Object.values(props.params);
        if (tmpArr.some((item: string) => item !== "")) {
            return props.params;
        } else return undefined;
    }, [props.params]);

    const getPnlData = (params: ClientAnalysisParams | undefined) => {
        setIsLoading(true);
        const formData = new FormData();
        if (params && !isEmptyOrNull(params)) {
            formData.append("server", params.server);
            formData.append("login", params.login.toString());
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_PNL_TREND_DATA}`, formData)
                .then((res: any) => {
                    if (res.data) {
                        setPnlData(res.data);
                    };
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("pnl charts", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setPnlData([]);
                }))
                .finally(() => setIsLoading(false));
        } else setIsLoading(false);
    };

    const getMarginLevelData = (params: ClientAnalysisParams | undefined) => {
        setIsMlcLoading(true);
        const formData = new FormData();
        if (params && !isEmptyOrNull(params)) {
            formData.append("server", params.server);
            formData.append("login", params.login.toString());
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_MARGIN_LEVEL_DATA}`, formData)
                .then((res: any) => {
                    setMarginLevelData(res.data.length > 0 ? res.data : []);
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("margin level chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setMarginLevelData([]);
                }))
                .finally(() => setIsMlcLoading(false));
        } else setIsMlcLoading(false);
    };

    useEffect(() => {
        getPnlData(params);
        getMarginLevelData(params);
        return () => {
            setIsLoading(false);
            setIsMlcLoading(false);
        };
    }, [params]);

    return (
        <div className="pnl-tab-container">
            <div className="tab-title">{`${params?.server} ${params?.login} Analysis Result`}</div>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <FloatingPnlChart data={pnlData} currency={params?.currency} isLoading={isLoading} />
                </Col>
                <Col span={8}>
                    <RealizedPnlChart data={pnlData} currency={params?.currency} isLoading={isLoading} />
                </Col>
                <Col span={8}>
                    <PnlChart data={pnlData} currency={params?.currency} isLoading={isLoading} />
                </Col>
                <Col span={12}>
                    <DpmTrendChart data={pnlData} isLoading={isLoading} />
                </Col>
                <Col span={12}>
                    <MarginLevelChart data={marginLevelData} currency={params?.currency} isLoading={isMlcLoading} />
                </Col>
            </Row>
        </div>
    );
};

export default PnlTab;


