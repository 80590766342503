import { Button, Col, Collapse, CollapseProps, Divider, Form, Input, Row, Select, Tag } from 'antd';
import React, { useEffect, useMemo, useState } from 'react'
import { FormComponent } from '../../../../../components/FormComponent';
import { CALLBACK_KEY, ComponentType, TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE, TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM, TOXIC_CLIENT_CHECK_ENTRY_STATUS } from '../../../../../constants';
import { BrandsList, CrmBrandList, FlexiDataTableOptionsProps, OptionType, RegulatorList, TccCommonFilterLabelList, TccCommonFilterSourceList, UsersList } from '../../../../../constants/type';
import { CaretRightOutlined, FilterFilled, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { ToOptionTypeList } from '../../../../../utils/array';
import { Link } from 'react-router-dom';
import { findObjectKeys, getAvailableObjectElementKeys, getObjectWithKeys, objectRemoveProps, resetObjectValues } from '../../../../../utils/object';
interface CustomGroupedFilterProps {
    callback: (type: number, data: any) => void;
    options: FlexiDataTableOptionsProps;
    initialValues?: any;
    servers: OptionType[];
    users: UsersList[];
    tcLabels: TccCommonFilterLabelList[];
    brands: BrandsList[];
    crmBrands: CrmBrandList[];
    regulators: RegulatorList[];
    filterFormInstance?: any;
    filterInitialValue?: any;
    tcSources: TccCommonFilterSourceList[];
}
const initialDefaultProps: CustomGroupedFilterProps = {
    options: {},
    callback: () => { },
    servers: [],
    users: [],
    tcLabels: [],
    brands: [],
    crmBrands: [],
    regulators: [],
    tcSources: [],
};

const CheckListCustomGroupedFilter = (props: CustomGroupedFilterProps) => {
    const [currentFilterForm] = Form.useForm();
    const filterForm = useMemo(() => (props.filterFormInstance ? props.filterFormInstance : currentFilterForm), [props.filterFormInstance]);
    const [currentProps, setCurrentProps] = useState(Object.assign({}, { ...initialDefaultProps }, props));
    const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<any>(props.filterInitialValue || {});
    const [selectedGcOption, setSelectedGcOption] = useState<string | undefined>(undefined);

    const getExtraElement = (): React.ReactNode => {
        let elements: React.ReactNode[] = [];
        let openNewLinkUrl: string = "";

        if (currentProps.options.extraButtons) {
            if (Array.isArray(currentProps.options.extraButtons) && currentProps.options.extraButtons.length > 0) {
                currentProps.options.extraButtons.forEach(x => {
                    elements.push(
                        <Button
                            key={`btn-ee-${Math.random()}`}
                            type={x.type || "default"}
                            htmlType="button"
                            style={{ marginLeft: "0.651vw", width: "auto" }}
                            onClick={event => {
                                event.preventDefault();
                                event.stopPropagation();
                                currentProps.callback(CALLBACK_KEY.OTHERS, x.value);
                            }}
                            {...(x.icon && {
                                icon: x.icon,
                            })}
                        >
                            {x.text}
                        </Button>
                    );
                });
            } else if (typeof currentProps.options.extraButtons === "function") {
                elements.push(currentProps.options.extraButtons());
            };
        }

        if (currentProps.options.add) {
            let createText = "Create";
            if (typeof currentProps.options.add !== "boolean") {
                if (typeof currentProps.options.add === "function") {
                    openNewLinkUrl = currentProps.options.add();
                } else {
                    createText = currentProps.options.add.text;
                }
            }
            elements.push(
                <Link to={openNewLinkUrl} key={`btn-add-${Math.random()}`}>
                    <Button
                        htmlType="button"
                        className="ft-flr-btn-add"
                        style={{ marginLeft: "0.651vw" }}
                        icon={<PlusOutlined style={{ fontSize: "0.875rem" }} />}
                        onClick={event => {
                            event.preventDefault();
                            event.stopPropagation();
                            currentProps.callback(CALLBACK_KEY.CREATE_NEW, {});
                        }}
                    >
                        {createText}
                    </Button>
                </Link>
            );
        }

        return elements;
    };

    const getFilteredDataTags = (filterValues: any) => {
        let filter_keys = getAvailableObjectElementKeys(filterValues);
        if (filter_keys.length === 0) return <></>;

        return (
            <Row className="tcc-custom-filter-tags-container">
                <Col span={24} className="filter-tags-div">
                    {filter_keys.map((x: string) => {
                        let displayText: any = `${filterValues[x]}`;

                        switch (x) {
                            case "accountId":
                            case "clientId":
                                displayText = filterValues[x].join(", ");

                                displayText = (
                                    <>
                                        <b>{`${x === "accountId" ? "Account ID" : "Client ID"}: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "serverId":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.servers.filter(
                                            y => `${y.value}` === `${z}`
                                        );
                                        if (foundItem.length > 0) {
                                            return foundItem[0].text;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Server: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "brandId":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.brands.filter(y => `${y.id}` === `${z}`);
                                        if (foundItem.length > 0) {
                                            return foundItem[0].brand;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Brand: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "crmBrandId":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.crmBrands.filter(y => `${y.id}` === `${z}`);
                                        if (foundItem.length > 0) {
                                            return foundItem[0].brand;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`CRM Brand: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "regulatorId":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.regulators.filter(y => `${y.id}` === `${z}`);
                                        if (foundItem.length > 0) {
                                            return foundItem[0].name;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Regulator: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "labelId":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.tcLabels.filter(y => `${y.labelId}` === `${z}`);
                                        if (foundItem.length > 0) {
                                            return foundItem[0].labelName;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Toxic Client Label: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "inspector":
                            case "createdBy":
                            case "lastUpdatedBy":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.users.filter(y => `${y.id}` === `${z}`);
                                        if (foundItem.length > 0) {
                                            return foundItem[0].name + " | " + foundItem[0].email;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`${x === "inspector" ? "Inspector" : x === "createdBy" ? "Created By" : "Last Updated By"}: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "status":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = TOXIC_CLIENT_CHECK_ENTRY_STATUS[z];
                                        return foundItem;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Status: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "pickupTeam":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM[z];
                                        return foundItem;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Pickup Team: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "sourceId":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = props.tcSources.filter(y => `${y.sourceId}` === `${z}`);
                                        if (foundItem.length > 0) {
                                            return foundItem[0].sourceName;
                                        }
                                        return undefined;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`Source: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "crmMappingMode":
                                displayText = filterValues[x]
                                    .map((z: any) => {
                                        let foundItem = TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE[z];
                                        return foundItem;
                                    })
                                    .filter((x: string | undefined) => x !== undefined)
                                    .join(", ");

                                displayText = (
                                    <>
                                        <b>{`CRM Mapping Mode: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "createdDateUtc":
                            case "lastUpdatedDateUtc":
                                /**
                                 *  for date range
                                 *  displayText = `${filterValues[x][0].format("YYYY-MM-DD")} ~ ${filterValues[x][1].format("YYYY-MM-DD")}`;
                                 *
                                 *  for datetime range
                                 *  displayText = `${filterValues[x][0].format("YYYY-MM-DD HH:mm:ss")} ~ ${filterValues[x][1].format("YYYY-MM-DD HH:mm:ss")}`;
                                 *
                                 *  soon to change to datetime range (after api is ready)
                                 */
                                displayText = `${filterValues[x][0].format("YYYY-MM-DD HH:mm:ss")} ~ ${filterValues[x][1].format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )}`;

                                displayText = (
                                    <>
                                        <b>{`${x === "createdDateUtc" ? "Created Date (Local)" : "Last Updated Date (Local)"}: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "deductionDate":
                                displayText = `${filterValues[x][0].format("YYYY-MM-DD")} ~ ${filterValues[x][1].format("YYYY-MM-DD")}`;

                                displayText = (
                                    <>
                                        <b>{`Deduction Date (Server): `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "issuedDate":
                                displayText = `${filterValues[x][0].format("YYYY-MM-DD")} ~ ${filterValues[x][1].format("YYYY-MM-DD")}`;

                                displayText = (
                                    <>
                                        <b>{`Issued Date (Server): `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            case "groupChangeFilter":
                                if (filterValues[x].isGroupChangeHasValue === undefined) return false;
                                else if (filterValues[x].isGroupChangeHasValue === "hasValue") {
                                    displayText = "Has value";
                                } else if (filterValues[x].isGroupChangeHasValue === "byKeyword") {
                                    if (filterValues[x].groupChange === undefined || filterValues[x].groupChange.length === 0) return false;
                                    else {
                                        displayText = `Search by keyword - ${filterValues[x].groupChange}`;
                                    }
                                }

                                displayText = (
                                    <>
                                        <b>{`Group Change: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${displayText}`}</span>
                                    </>
                                );
                                break;
                            default:
                                let displayTitle: string = "";
                                if (x === "illegalProfit") displayTitle = "Illegal Profit";
                                else if (x === "suggestedDeduction") displayTitle = "Suggested Deduction";
                                else if (x === "actualDeduction") displayTitle = "Actual Deduction";
                                else if (x === "note") displayTitle = "Note";
                                else if (x === "addingCidTool") displayTitle = "Adding CID Tool";
                                else if (x === "closeOnly") displayTitle = "Close Only";
                                else if (x === "readOnly") displayTitle = "Read Only";
                                else if (x === "closeAccount") displayTitle = "Close Account";
                                else if (x === "kyc") displayTitle = "KYC";
                                else if (x === "leverage") displayTitle = "Leverage";
                                else if (x === "warningLetter") displayTitle = "Warning Letter";
                                else if (x === "withdrawBan") displayTitle = "Withdraw Ban";
                                else if (x === "creditPromotion") displayTitle = "Credit & Promotion";
                                else if (x === "openAccount") displayTitle = "Open Account";
                                else if (x === "swap") displayTitle = "Swap";
                                else if (x === "sBook") displayTitle = "S Book";

                                displayText = (
                                    <>
                                        <b>{`${displayTitle}: `}</b>
                                        <>&nbsp;</>
                                        <span className="text-wrap">{`${filterValues[x]}`}</span>
                                    </>
                                );
                                break;
                        }

                        return (
                            <Tag
                                key={`fdb-fltg-ky-${x}`}
                                closable
                                onClose={() =>
                                    findObjectKeys(filteredData, [x]) &&
                                    componentCallback(CALLBACK_KEY.FILTER_FORM_SUBMIT, { ...objectRemoveProps(filteredData, [x]), [x]: undefined })
                                }
                            >
                                {displayText}
                            </Tag>
                        );
                    })}
                </Col>
            </Row>
        );
    };

    const componentCallback = (type: CALLBACK_KEY, data: any) => {
        switch (type) {
            case CALLBACK_KEY.FILTER_FORM_SUBMIT:
                data = Object.keys(data).reduce((obj: any, i: any) => {
                    obj[i] = (Array.isArray(data[i]) ? data[i].length === 0 : data[i] === null) ? undefined : data[i];
                    return obj;
                }, {});
                setFilteredData(data);
                setIsCollapsed(false);
                break;
            case CALLBACK_KEY.COLLAPSE:
                setIsCollapsed(!isCollapsed);
                break;
            case CALLBACK_KEY.FILTER_FORM_RESET:
                let resetData = resetObjectValues(data);
                if (currentProps.options.resetFollowDefaultInitFilterValue === undefined) {
                    resetData = { ...resetData, ...props.filterInitialValue };
                } else {
                    if (typeof currentProps.options.resetFollowDefaultInitFilterValue === "boolean") {
                        if (currentProps.options.resetFollowDefaultInitFilterValue) {
                            resetData = { ...resetData, ...props.filterInitialValue };
                        }
                    } else {
                        resetData = {
                            ...resetData,
                            ...getObjectWithKeys(props.filterInitialValue, currentProps.options.resetFollowDefaultInitFilterValue as string[]),
                        };
                    }
                }
                setFilteredData(resetData);
                props.callback && props.callback(CALLBACK_KEY.FILTER_FORM_SUBMIT, resetData);
                return;
            default:
                break;
        }

        props.callback && props.callback(type, data);
    };

    useEffect(() => {
        if (props) {
            setCurrentProps(Object.assign({}, { ...initialDefaultProps }, props));
            filterForm.setFieldsValue(props.filterInitialValue || {});
        }

        if (filteredData) {
            filterForm.setFieldsValue(filteredData);
        }

        return () => { };
    }, [props, filteredData]);

    let extraTableElement: any = getExtraElement();

    const collapseProps: CollapseProps = {
        onChange: () => { },
        className: "filter-by-category-collapsible",
        expandIcon: ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />,
        destroyInactivePanel: false,
    };

    return (
        <>
            <div className="toxic-client-check-custom-groupedfilter-component">
                <Collapse
                    activeKey={isCollapsed ? ["custom-grouped-flexi-filter-panel"] : []}
                    collapsible="header"
                    bordered={false}
                    className="custom-flexi-filter"
                    onChange={key => {
                        componentCallback(CALLBACK_KEY.COLLAPSE, key);
                    }}
                    expandIconPosition="start"
                    expandIcon={panelProps => {
                        return (
                            <Button
                                type="ghost"
                                icon={
                                    panelProps.isActive ? (
                                        <FilterOutlined style={{ fontSize: "1.125rem" }} />
                                    ) : (
                                        <FilterFilled style={{ fontSize: "1.125rem" }} />
                                    )
                                }
                            >
                                <span style={{ fontSize: "0.9375rem" }}>Filter</span>
                            </Button>
                        );
                    }}
                >
                    <Collapse.Panel header="" key="custom-grouped-flexi-filter-panel" className="flexi-filter-panel" extra={extraTableElement}>
                        <div>
                            <Form
                                form={filterForm}
                                layout="vertical"
                                autoComplete="off"
                                initialValues={currentProps.filterInitialValue}
                                onFinish={values => componentCallback(CALLBACK_KEY.FILTER_FORM_SUBMIT, filterForm.getFieldsValue(true))}
                                onFinishFailed={values => componentCallback(CALLBACK_KEY.FILTER_FORM_SUBMIT_FAILED, values)}
                            >
                                <div className="fixed-size-grouped-filter-container nice-scrollbar">
                                    <Row gutter={24} style={{ width: "100%", marginBottom: 10 }}>
                                        <Col span={6}>
                                            <FormComponent
                                                label="Account ID"
                                                name={"accountId"}
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    value: [],
                                                    inputProps: {
                                                        mode: "tags",
                                                        placeholder: "Please input account id",
                                                        tokenSeparators: [",", "，", " "],
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <FormComponent
                                                label="Client ID"
                                                name={"clientId"}
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    value: [],
                                                    inputProps: {
                                                        mode: "tags",
                                                        placeholder: "Please input client id",
                                                        tokenSeparators: [",", "，", " "],
                                                    },
                                                }}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <FormComponent
                                                label="Toxic Client Label"
                                                name={"labelId"}
                                                extra={{
                                                    type: ComponentType.dropdown,
                                                    value: props.tcLabels.map((x: TccCommonFilterLabelList) => ({
                                                        text: x.labelName,
                                                        value: x.labelId,
                                                    })),
                                                    inputProps: {
                                                        mode: "multiple",
                                                    },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24} style={{ width: "100%" }}>
                                        <Col span={6}>
                                            <Row className="custom-form-filter-row">
                                                <Col span={24}>
                                                    <FormComponent
                                                        label="Inspector"
                                                        name={"inspector"}
                                                        extra={{
                                                            type: ComponentType.dropdown,
                                                            value: props.users.map((x: UsersList) => ({
                                                                text: x.name + " | " + x.email,
                                                                value: x.id,
                                                            })),
                                                            inputProps: {
                                                                mode: "multiple",
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row className="custom-form-filter-row">
                                                <Col span={24}>
                                                    <FormComponent
                                                        label="Issued Date (Server)"
                                                        name={"issuedDate"}
                                                        extra={{
                                                            type: ComponentType.daterange,
                                                            value: "",
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row className="custom-form-filter-row">
                                                <Col span={24}>
                                                    <FormComponent
                                                        label="Created Date (Local)"
                                                        name={"createdDateUtc"}
                                                        extra={{
                                                            type: ComponentType.daterange,
                                                            value: "",
                                                            inputProps: {
                                                                showTime: { format: "HH:mm:ss" },
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={[24, 12]}>
                                        <Col span={12}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="Account Info" key="1">
                                                    <Row gutter={[24, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Server"
                                                                name={"serverId"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.servers.sort((a: any, b: any) => a.text < b.text ? -1 : 1),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Brand"
                                                                name={"brandId"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.brands.map((x: BrandsList) => ({
                                                                        text: x.brand,
                                                                        value: x.id,
                                                                    })),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={6}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="Account Restriction" key="6">
                                                    <Row className="custom-form-filter-row" gutter={[12, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Adding CID Tool"
                                                                name={"addingCidTool"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Close Only"
                                                                name={"closeOnly"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Read Only"
                                                                name={"readOnly"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="S Book"
                                                                name={"sBook"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="custom-form-filter-row" gutter={12}>
                                                        <Col span={24}>
                                                            <Form.Item label="Group Change">
                                                                <Input.Group compact>
                                                                    <Form.Item name={["groupChangeFilter", "isGroupChangeHasValue"]} noStyle>
                                                                        <Select
                                                                            allowClear
                                                                            style={{ width: "45%" }}
                                                                            placeholder="Please select option"
                                                                            onChange={(value: any, opt: any) => {
                                                                                setSelectedGcOption(value === undefined ? undefined : opt.value);
                                                                                if (opt.value === "hasValue" || value === undefined) {
                                                                                    filterForm.setFieldValue(["groupChangeFilter", "groupChange"], undefined);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Select.Option value={"hasValue"}>Has value</Select.Option>
                                                                            <Select.Option value={"byKeyword"}>Search by keyword</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                    <Form.Item name={["groupChangeFilter", "groupChange"]} noStyle>
                                                                        <Input
                                                                            allowClear
                                                                            style={{ width: "55%" }}
                                                                            placeholder="Input keyword"
                                                                            disabled={selectedGcOption === "hasValue" || selectedGcOption === undefined}
                                                                        />
                                                                    </Form.Item>
                                                                </Input.Group>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={6}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="Client Restriction" key="7">
                                                    <Row className="custom-form-filter-row" gutter={[12, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Close Account"
                                                                name={"closeAccount"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="KYC"
                                                                name={"kyc"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Leverage"
                                                                name={"leverage"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Warning Letter"
                                                                name={"warningLetter"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Withdraw Ban"
                                                                name={"withdrawBan"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Divider orientation="left" className="divider-nogap"></Divider>
                                                    <div className="tcc-custom-filter-group-title">Blacklist</div>
                                                    <Row gutter={[12, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Credit & Promotion"
                                                                name={"creditPromotion"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Open Account"
                                                                name={"openAccount"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Swap"
                                                                name={"swap"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: [
                                                                        { text: "Yes", value: true },
                                                                        { text: "No", value: false },
                                                                    ],
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={12}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="CRM Info" key="2">
                                                    <Row gutter={[24, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="CRM Mapping Mode"
                                                                name={"crmMappingMode"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_CRM_MAPPING_MODE),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="CRM Brand"
                                                                name={"crmBrandId"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.crmBrands.map((x: CrmBrandList) => ({
                                                                        text: x.brand,
                                                                        value: x.id,
                                                                    })),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Regulator"
                                                                name={"regulatorId"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.regulators.map((x: RegulatorList) => ({
                                                                        text: x.name,
                                                                        value: x.id,
                                                                    })),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={12}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="Toxic" key="4">
                                                    <Row gutter={[24, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Illegal Profit"
                                                                name={"illegalProfit"}
                                                                extra={{
                                                                    type: ComponentType.numberrange2,
                                                                    value: "",
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Suggested Deduction"
                                                                name={"suggestedDeduction"}
                                                                extra={{
                                                                    type: ComponentType.numberrange2,
                                                                    value: "",
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Actual Deduction"
                                                                name={"actualDeduction"}
                                                                extra={{
                                                                    type: ComponentType.numberrange2,
                                                                    value: "",
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Deduction Date (Server)"
                                                                name={"deductionDate"}
                                                                extra={{
                                                                    type: ComponentType.daterange,
                                                                    value: "",
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={12}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="General" key="3">
                                                    <Row gutter={[24, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Status"
                                                                name={"status"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_STATUS),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Pickup Team"
                                                                name={"pickupTeam"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: ToOptionTypeList(TOXIC_CLIENT_CHECK_ENTRY_PICKUP_TEAM),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Note"
                                                                name={"note"}
                                                                extra={{
                                                                    type: ComponentType.text,
                                                                    value: "",
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Source"
                                                                name={"sourceId"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.tcSources.map((x: TccCommonFilterSourceList) => ({
                                                                        text: x.sourceName,
                                                                        value: x.sourceId
                                                                    })),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={12}>
                                            <Collapse {...collapseProps}>
                                                <Collapse.Panel header="Date" key="5">
                                                    <Row gutter={[24, 12]}>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Created By"
                                                                name={"createdBy"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.users.map((x: UsersList) => ({
                                                                        text: x.name + " | " + x.email,
                                                                        value: x.id,
                                                                    })),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Last Updated By"
                                                                name={"lastUpdatedBy"}
                                                                extra={{
                                                                    type: ComponentType.dropdown,
                                                                    value: props.users.map((x: UsersList) => ({
                                                                        text: x.name + " | " + x.email,
                                                                        value: x.id,
                                                                    })),
                                                                    inputProps: {
                                                                        mode: "multiple",
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col span={12}>
                                                            <FormComponent
                                                                label="Last Updated Date (Local)"
                                                                name={"lastUpdatedDateUtc"}
                                                                extra={{
                                                                    type: ComponentType.daterange,
                                                                    value: "",
                                                                    inputProps: {
                                                                        showTime: { format: "HH:mm:ss" },
                                                                    },
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </div>
                                <Divider orientation="left" className="divider-nogap"></Divider>
                                <Row style={{ width: "100%" }}>
                                    <Col span={24} key="flt-f-c-btns" className="flt-f-c-btns">
                                        <Button
                                            type="default"
                                            onClick={e => {
                                                e.preventDefault();
                                                filterForm.resetFields();
                                                componentCallback(CALLBACK_KEY.FILTER_FORM_RESET, currentProps.filterInitialValue);
                                            }}
                                        >
                                            Reset
                                        </Button>
                                        <Button type="primary" htmlType="submit">
                                            Search
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
            {!isCollapsed && getFilteredDataTags(filteredData)}
        </>
    );
};

export default CheckListCustomGroupedFilter;
