import { useNavigate } from "react-router-dom";
import RouteHelper from "@/helpers/routeHelper";
import { auth_route } from "@/routes";
// Custom hook to get navigate function
export const useKBarActions = () => {
    const routeHelper = new RouteHelper(auth_route);
    const allRoutes = routeHelper.getNavigationMenu();

    const navigate = useNavigate();

    // generate my kbar actions

    const generateActions = (routes: any, parentPath = "", parentName = "") => {
        let actions: any[] = [];

        routes.forEach((route: any) => {
            if (route.showInNavBar) {
                // const path = `${parentPath}${route.path}`;

                const path = `${parentPath}${route.key}`;
                const action = {
                    id: route.key,
                    name: route.title,
                    keywords: route.title.toLowerCase(),
                    //perform: () => navigate(path),
                    // if this is parentName, don't navigate
                    perform: () => {
                        if (route.subMenu && route.subMenu.length > 0) {
                            // do nothing
                        } else {
                            // check if routed path has double "//", remove it
                            const newPath = path.replace("//", "/");
                            navigate(newPath);
                        }
                    },
                };

                if (parentName) {
                    // @ts-ignore
                    action.section = parentName.toUpperCase();
                }

                // if it is the most outer parent with submenu, don't render it
                // if type is group, don't render it
                if ((parentName === "" && route.submenu && route.submenu.length > 0) || route.type === "group") {
                    // do nothing
                } else {
                    actions.push(action);
                }

                if (route.submenu && route.submenu.length > 0) {
                    const subActions = generateActions(route.submenu, /* `${path}/` */ "", route.title);
                    actions = [...actions, ...subActions];
                }
            }
        });

        return actions;
    };

    const kbarActions = generateActions(allRoutes);

    return kbarActions;
};
