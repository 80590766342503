import { useMemo } from "react";
import { ClientAnalysisMarginLevelData } from "@/constants/type";
import CustomSkeleton from "@/components/Common/Skeleton";
import { Line } from "@ant-design/plots";
import { currencyRender } from "@/utils/Common";
import { Datum } from "@ant-design/graphs";
import EmptyData from "@/components/Common/Empty";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
]);

interface MarginLevelChartProps {
    data: ClientAnalysisMarginLevelData[];
    currency: string | undefined;
    isLoading: boolean;
};

const MarginLevelChart = (props: MarginLevelChartProps) => {
    const options = useMemo(() => {
        let dateTimes: string[] = [];
        let marginLevelSeries: string[] = [];

        props.data.forEach((item: ClientAnalysisMarginLevelData) => {
            dateTimes.push(item.createTime);
            marginLevelSeries.push(item.marginLevel.toFixed(2));
        });

        return {
            // title: {
            //     text: "Margin Level",
            // },
            // legend: {
            //     left: "left",
            // },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
            },
            toolbox: {
                show: true,
                itemSize: 20,
                iconStyle: {
                    color: "#fff",
                },
                feature: {
                    show: true,
                    dataView: { show: true, readOnly: false },
                    saveAsImage: {
                        show: true,
                        title: "Save Image As",
                    },
                },
            },
            xAxis: [
                {
                    name: "Date",
                    splitLine: { show: false },
                    data: dateTimes,
                },
            ],
            yAxis: [
                {
                    name: "Margin Level",
                    axisLabel: {
                        formatter: "{value} %",
                    },
                    splitLine: {
                        show: true,
                    },
                },
            ],
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                },
                {
                    show: true,
                    type: "slider",
                    start: 0,
                    end: 100,
                },
                {
                    textStyle: {
                        color: "#fff",
                    },
                },
            ],
            series: [
                {
                    name: "Margin Level",
                    type: "line",
                    symbol: "none",
                    color: "#FDA233",
                    lineStyle: {
                        color: "#FDA233",
                    },
                    label: { show: true },
                    emphasis: { label: { show: true } },
                    data: marginLevelSeries,
                },
            ],
        }
    }, [props.data]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Margin Level</span>
            </div>
            {props.isLoading ? (
                <CustomSkeleton rows={7} />
            ) : props.data.length > 0
                ? (
                    <ReactEChartsCore
                        echarts={echarts}
                        option={options}
                        showLoading={props.isLoading}
                        notMerge={true}
                        style={{ minHeight: 400 }}
                    />
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default MarginLevelChart;