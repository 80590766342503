import { useEffect, useMemo, useState } from "react";
import { ClientAnalysisParams, ClientAnalysisSymbolByVolData, ClientAnalysisTradeTimeData } from "@/constants/type";
import { Col, Row } from "antd";
import TradeVolumePie from "./AssociationTabComponents/TradeVolumePie";
import { isEmptyOrNull } from "@/utils/string";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import TradeTimePie from "./AssociationTabComponents/TradeTimePie";
import TradingTypePie from "./AssociationTabComponents/TradingTypePie";
import SymbolPnlChart from "./AssociationTabComponents/SymbolPnlChart";
import DailyExposureChart from "./AssociationTabComponents/DailyExposureChart";
import SymbolData from "./AssociationTabComponents/SymbolData";

interface AssociationTabProps {
    params: ClientAnalysisParams;
    isInitialLoading: boolean;
};

const AssociationTab = (props: AssociationTabProps) => {
    const [symVolData, setSymVolData] = useState<ClientAnalysisSymbolByVolData[]>([]);
    const [isSvLoading, setIsSvLoading] = useState<boolean>(props.isInitialLoading);
    const [tradeTimeData, setTradeTimeData] = useState<ClientAnalysisTradeTimeData[]>([]);
    const [isTtLoading, setIsTtLoading] = useState<boolean>(props.isInitialLoading);

    const params = useMemo(() => {
        let tmpArr = Object.values(props.params);
        if (tmpArr.some((item: string) => item !== "")) {
            return props.params;
        } else return undefined;
    }, [props.params]);

    const getSymbolVolumeData = (params: ClientAnalysisParams | undefined) => {
        setIsSvLoading(true);
        const formData = new FormData();
        if (params && !isEmptyOrNull(params)) {
            formData.append("server", params.server);
            formData.append("login", params.login.toString());
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_SYMBOL_VOLUME_DATA}`, formData)
                .then((res: any) => {
                    setSymVolData(res.data.length > 0 ? res.data : []);
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("symbol by volume chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setSymVolData([]);
                }))
                .finally(() => setIsSvLoading(false));
        } else setIsSvLoading(false);
    };

    const getTradeTimeData = (params: ClientAnalysisParams | undefined) => {
        setIsTtLoading(true);
        const formData = new FormData();
        if (params && !isEmptyOrNull(params)) {
            formData.append("server", params.server);
            formData.append("login", params.login.toString());
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_TRADE_TIME_DATA}`, formData)
                .then((res: any) => {
                    setTradeTimeData(res.data.length > 0 ? res.data : []);
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("session chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setTradeTimeData([]);
                }))
                .finally(() => setIsTtLoading(false));
        } else setIsTtLoading(false);
    };

    useEffect(() => {
        getSymbolVolumeData(params);
        getTradeTimeData(params);
        return () => {
            setIsSvLoading(false);
            setIsTtLoading(false);
        };
    }, []);

    return (
        <div className="association-tab-container">
            <div className="tab-title">{`${params?.server} ${params?.login} Analysis Result`}</div>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <TradeVolumePie data={symVolData} isLoading={isSvLoading} />
                </Col>
                <Col span={8}>
                    <TradeTimePie data={tradeTimeData} isLoading={isTtLoading} />
                </Col>
                <Col span={8}>
                    <TradingTypePie server={params?.server} login={params?.login} isInitialLoading={props.isInitialLoading} />
                </Col>
                <Col span={16}>
                    <SymbolPnlChart server={params?.server} login={params?.login} isInitialLoading={props.isInitialLoading} />
                </Col>
                <Col span={8}>
                    <DailyExposureChart server={params?.server} login={params?.login} isInitialLoading={props.isInitialLoading} />
                </Col>
                <Col span={24}>
                    <SymbolData server={params?.server} login={params?.login} isInitialLoading={props.isInitialLoading} />
                </Col>
            </Row>
        </div>
    );
};

export default AssociationTab;