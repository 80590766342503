import React from "react";
import useRCPriceBackendOpt from "../../../../hooks/useRCPriceBackendOpt";

import RestartServers from "../RestartServers";
import { Typography } from "antd";
const PriceConnector = () => {
    const { rcPriceBackendOpt } = useRCPriceBackendOpt({ type: "Price Connector" });
    const { Title } = Typography;

    return (
        <div>
            <Title level={5}>Price Connector</Title>
            <RestartServers data={rcPriceBackendOpt} serverDetailsType={"2"} isPriceConnector={true} />
        </div>
    );
};

export default PriceConnector;
