import { useMemo } from "react";
import EmptyData from "@/components/Common/Empty";
import CustomSkeleton from "@/components/Common/Skeleton";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { ClientAnalysisSymbolByVolData } from "@/constants/type";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

interface TradeVolumePieProps {
    data: ClientAnalysisSymbolByVolData[];
    isLoading: boolean;
};

const TradeVolumePie = (props: TradeVolumePieProps) => {
    const options = useMemo(() => {
        let data = props.data.map((x: ClientAnalysisSymbolByVolData) => ({
            name: x.symbol,
            value: x.volume.toFixed(5),
        }));
        return {
            // title: {
            //     text: "Volume By Symbol",
            // },
            tooltip: {
                trigger: "item",
                formatter: function (params: any) {
                    return (
                        params.name +
                        "<br>" +
                        params.marker +
                        params.seriesName +
                        ": " +
                        parseFloat(params.value)
                            .toFixed(2)
                            .replace(
                                /\d(?=(\d{3})+\.)/g,
                                "$&,"
                            ) +
                        " (" +
                        params.percent +
                        "%)"
                    );
                },
            },
            toolbox: {
                show: true,
                itemSize: 20,
                feature: {
                    dataView: { show: true, readOnly: false },
                    saveAsImage: {
                        show: true,
                        title: "Save Image",
                    },
                },
            },
            series: [
                {
                    name: "Volume",
                    type: "pie",
                    radius: "55%",
                    center: ["50%", "50%"],
                    label: {
                        show: true,
                        formatter: "{b} : {d}%",
                    },
                    labelLine: {
                        show: true,
                    },
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                    data: data,
                },
            ],
        };
    }, [props.data]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Volume by Symbol</span>
            </div>
            {props.isLoading ? (
                <CustomSkeleton rows={7} />
            ) : props.data.length > 0
                ? (
                    <ReactEChartsCore
                        echarts={echarts}
                        option={options}
                        showLoading={props.isLoading}
                        notMerge={true}
                        style={{ minHeight: 400 }}
                    />
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default TradeVolumePie;