import React, { useCallback, useState } from "react";

import { useRCPriceBackendOptDetails, useRCPriceBackendOptMutations, type IRCPriceBackendOpt } from "../../../hooks/useRCPriceBackendOpt";
import { Button, Col, Input, Modal, Row, Space, Tag, notification } from "antd";
import { matchSorter } from "match-sorter";
import { CheckSquareOutlined, ReadOutlined, ReloadOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { colorMap, colorMapRGB } from "../../SystemMonitor/StatusMonitor/statusUtils";
import CodeBlock from "./CodeBlock";
import MessageCard from "../../SystemMonitor/components/MessageCard";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";

interface restartSingleServerProps {
    priceEnum: number;
    serverDn: string;
    serverUno: number;
    type: number;
}

const RestartServers = ({
    data,
    canBatchRestart = false,
    serverDetailsType = "1",
    isPriceConnector = false,
    isPriceAnalysisTool = false,
    isTickReciverTools = false,
}: {
    data: IRCPriceBackendOpt[];
    canBatchRestart?: boolean;
    serverDetailsType?: string;
    isPriceConnector?: boolean;
    isPriceAnalysisTool?: boolean;
    isTickReciverTools?: boolean;
}) => {
    const [searchText, setSearchText] = useState("");
    const result = matchSorter(data, searchText, { keys: ["serverDn"] });
    const [selected, setSelected] = useState<IRCPriceBackendOpt[]>([]);
    const selectedServerIDs = selected?.map(item => item.serverUno) || [];

    const { restartServers, isLoadingRestartServers, restartServersError } = useRCPriceBackendOptMutations();
    const [restartSingleServer, setRestartSingleServer] = useState<number | restartSingleServerProps | null>(null);
    const [readServerDetails, setReadServerDetails] = useState<{ param: string; type: string } | null>(null);

    const [openRestartModal, setOpenRestartModal] = useState(false);
    // console.log(data);
    const handleRestartServer = useCallback(
        async (values: any, fnCallback: Function | undefined = undefined) => {
            try {
                const resp = await restartServers({ ids: values, isSingleRun: isPriceConnector === true });
                if (isPriceConnector) {
                    if (resp.data === "ok") {
                        notification.success({ message: "Restarting server", description: `Reload ${values.serverDn} Broadcaster successfully` });
                        setSelected([]);
                    } else {
                        notification.error({ message: "Restarting server", description: `Reload ${values.serverDn} Broadcaster Fail` });
                    }
                } else {
                    if (resp.data === "ok") {
                        notification.success({ message: "Restarting servers", description: "Successfully restarted servers" });
                        setSelected([]);
                    } else {
                        notification.error({ message: "Restarting servers", description: "Error while restarting servers" });
                    }
                }
            } catch (error) {
                if (isPriceConnector) {
                    notification.error({
                        message: `Reload ${values.serverDn} Broadcaster Fail`,
                    });
                } else {
                    notification.error({
                        message: "Error while restarting servers",
                        // @ts-ignore
                        description: error?.response?.data?.message || error?.message || "Unknown error",
                    });
                }
            }
            fnCallback && fnCallback();
        },
        [isPriceConnector, restartServers]
    );

    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Input value={searchText} onChange={e => setSearchText(e.target.value)} placeholder={`Search from ${data.length} servers`} />
                {canBatchRestart && (
                    <Space wrap style={{ width: "100%" }}>
                        {/* select all, if select >1, deselect all */}
                        <Button
                            type="text"
                            style={{ marginLeft: "auto" }}
                            onClick={() => {
                                if (selected.length === data.length) {
                                    setSelected([]);
                                } else {
                                    setSelected(data);
                                }
                            }}
                        >
                            {selected.length === data.length ? "Deselect All" : "Select All"}
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => setOpenRestartModal(true)}
                            disabled={selected.length <= 0 || isLoadingRestartServers}
                        >
                            Batch Restart {selected.length >= 1 && ` ${selected.length} ${selected.length > 1 ? "servers" : "server"}`}
                        </Button>
                    </Space>
                )}
                <section>
                    <Row gutter={[4, 4]} style={{ minWidth: 220 }}>
                        {result?.map(each => {
                            const isSelected = selected.find(item => item.serverUno === each.serverUno);
                            return (
                                <Col key={each.serverUno} xs={12} sm={8} md={6}>
                                    <motion.div
                                        style={{
                                            minWidth: 100,
                                            width: "100%",
                                            height: "100%",
                                            border: "1px solid",
                                            borderColor: "#f0f0f0",
                                            padding: " 0.5rem",
                                            cursor: canBatchRestart ? "pointer" : "default",
                                        }}
                                        animate={{
                                            borderColor: selected.find(item => item.serverUno === each.serverUno) ? colorMap.success : "#f0f0f0",
                                            backgroundColor: selected.find(item => item.serverUno === each.serverUno)
                                                ? `rgba(${colorMapRGB.success},0.05)`
                                                : "rgba(255,255,255,0)",
                                        }}
                                        whileHover={{
                                            borderColor: colorMap.primary,
                                        }}
                                        onClick={() => {
                                            canBatchRestart &&
                                                setSelected(prev => {
                                                    if (prev.find(item => item.serverUno === each.serverUno)) {
                                                        return prev.filter(item => item.serverUno !== each.serverUno);
                                                    } else {
                                                        return [...prev, each];
                                                    }
                                                });
                                        }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                                            <p>
                                                <strong>{each.serverDn}</strong>
                                            </p>
                                            {isSelected && <CheckSquareOutlined />}
                                        </div>
                                        <Space wrap>
                                            <Button
                                                type="text"
                                                danger
                                                size="small"
                                                icon={<ReloadOutlined />}
                                                onClick={e => {
                                                    // prevent bubble
                                                    e.stopPropagation();
                                                    if (isPriceConnector) {
                                                        setRestartSingleServer({
                                                            ...each,
                                                            priceEnum: 2,
                                                        });
                                                    } else {
                                                        setRestartSingleServer(each.serverUno);
                                                    }
                                                }}
                                                disabled={isLoadingRestartServers}
                                            >
                                                Restart
                                            </Button>
                                            <Button
                                                type="ghost"
                                                size="small"
                                                icon={<ReadOutlined />}
                                                onClick={e => {
                                                    // prevent bubble
                                                    e.stopPropagation();
                                                    if (isPriceAnalysisTool) {
                                                        setReadServerDetails({ param: "PriceAnalysisTool", type: "1" });
                                                    } else {
                                                        setReadServerDetails({ param: each.serverDn, type: serverDetailsType });
                                                    }
                                                }}
                                            >
                                                Details
                                            </Button>
                                        </Space>
                                    </motion.div>
                                </Col>
                            );
                        })}
                    </Row>
                </section>
            </Space>
            <Modal
                title="Restart Servers"
                open={openRestartModal}
                onOk={() => {
                    handleRestartServer(selectedServerIDs, () => setOpenRestartModal(false));
                }}
                okText="Restart"
                onCancel={() => setOpenRestartModal(false)}
                confirmLoading={isLoadingRestartServers}
            >
                <p>
                    Are you sure you want to restart {selected.length} {selected.length > 1 ? "servers" : "server"}?
                </p>
                <Space wrap size={2}>
                    {selected.map(each => (
                        <Tag key={each.serverUno} style={{ margin: 0 }}>
                            {each.serverDn}
                        </Tag>
                    ))}
                </Space>
            </Modal>
            <Modal
                maskClosable={false}
                title="Restart Server"
                open={restartSingleServer !== null}
                onOk={() => {
                    handleRestartServer(isPriceConnector ? restartSingleServer : [restartSingleServer as number], () => setRestartSingleServer(null));
                }}
                okText="Restart"
                onCancel={() => setRestartSingleServer(null)}
                confirmLoading={isLoadingRestartServers}
            >
                <p>
                    Are you sure you want to restart this server?
                    <br />
                    <strong></strong>
                </p>
                <Tag style={{ margin: 0 }}>
                    {restartSingleServer === null
                        ? ""
                        : typeof restartSingleServer === "number"
                        ? data.find(item => item.serverUno === restartSingleServer)?.serverDn
                        : (restartSingleServer as restartSingleServerProps).serverDn}
                </Tag>
            </Modal>
            {readServerDetails && (
                <ServerDetails
                    openModal={true}
                    param={readServerDetails.param}
                    type={readServerDetails.type}
                    isTickReciverTools={isTickReciverTools}
                    closeModal={() => setReadServerDetails(null)}
                />
            )}
        </>
    );
};

const ServerDetails = ({
    openModal = false,
    param,
    type = "1",
    closeModal,
    isTickReciverTools = false,
}: {
    openModal: boolean;
    param: string;
    type?: string;
    isTickReciverTools?: boolean;
    closeModal: () => void;
}) => {
    const combined = `${param},${type}`;
    const { rcPriceBackendOptDetails, isLoading } = useRCPriceBackendOptDetails({ param: combined, isTickReciverTools: isTickReciverTools });
    console.log("rcPriceBackendOptDetails", rcPriceBackendOptDetails);

    return (
        <>
            <Modal
                title={`Server Details for ${param}`}
                open={openModal}
                width={1000}
                onOk={() => {
                    closeModal();
                }}
                onCancel={() => {
                    closeModal();
                }}
                confirmLoading={false}
            >
                {isLoading && !rcPriceBackendOptDetails ? (
                    <MessageCard type="info">Loading server details</MessageCard>
                ) : rcPriceBackendOptDetails ? (
                    <CodeBlock code={rcPriceBackendOptDetails} />
                ) : (
                    <MessageCard type="warning">Something went wrong, please let us know and we will get back to you</MessageCard>
                )}
            </Modal>
        </>
    );
};

export default RestartServers;
