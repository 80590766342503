import { useMemo } from "react";
import { ClientAnalysisPnlData } from "@/constants/type";
import CustomSkeleton from "@/components/Common/Skeleton";
import EmptyData from "@/components/Common/Empty";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { LineChart, ScatterChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
    DataZoomComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    DataZoomComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    LineChart,
    ScatterChart,
    CanvasRenderer,
]);

interface PnlChartProps {
    data: ClientAnalysisPnlData[];
    currency: string | undefined;
    isLoading: boolean;
};

const PnlChart = (props: PnlChartProps) => {
    const options = useMemo(() => {
        let dates: string[] = [];
        let totalProfitSeries: number[] = [];
        let groupChangeSeries: any[] = [];

        props.data.forEach((item: ClientAnalysisPnlData) => {
            dates.push(item.dateYMD);
            totalProfitSeries.push(item.totalProfit);
            if (item.groupChanges != null) {
                groupChangeSeries.push([
                    item.dateYMD,
                    item.totalProfit,
                    item.groupChanges,
                ]);
            }
        });

        return {
            // title: {
            //     text: "PNL",
            // },
            grid: {
                left: "1%",
                containLabel: true,
            },
            legend: {
                left: "left",
            },
            tooltip: {
                trigger: "axis",
            },
            toolbox: {
                show: true,
                itemSize: 20,
                iconStyle: {
                    color: "#fff",
                },
                feature: {
                    show: true,
                    dataView: { show: true, readOnly: false },
                    saveAsImage: {
                        show: true,
                        title: "Save Image As",
                    },
                },
            },
            xAxis: {
                name: "Date",
                show: true,
                type: "category",
                data: dates,
            },
            yAxis: {
                type: "value",
                name: `PNL (${props.currency})`,
                splitArea: { show: true },
            },
            dataZoom: [
                {
                    type: "inside",
                    start: 0,
                    end: 100,
                },
                {
                    show: true,
                    type: "slider",
                    start: 0,
                    end: 100,
                },
                {
                    textStyle: {
                        color: "#fff",
                    },
                },
            ],
            series: [
                {
                    name: "PNL",
                    type: "line",
                    symbol: "none",
                    color: "#FDA233",
                    lineStyle: {
                        color: "#FDA233",
                    },
                    label: { show: true },
                    emphasis: { label: { show: true } },
                    data: totalProfitSeries,
                },
                {
                    name: "Group Changes",
                    type: "scatter",
                    symbolSize: 30,
                    zlevel: 2,
                    symbol: "pin",
                    color: "#21d8c3",
                    emphasis: {
                        label: {
                            show: true,
                            position: "insideBottom",
                            color: "#21d8c3",
                            fontSize: 15,
                            backgroundColor: "rgb(0, 0, 0)",
                            formatter: function (param: any) {
                                if (param.data[2] != null) {
                                    let result = "";
                                    let count = 0;
                                    let aryLength = param.data[2].length;
                                    param.data[2].forEach((item: any) => {
                                        result +=
                                            item.convertGroupTimeStr +
                                            "\n" +
                                            item.fromGroup +
                                            "\n" +
                                            item.toGroup;
                                        if (count !== aryLength - 1) {
                                            result += "\n\n";
                                        }
                                        count += 1;
                                    });

                                    return result;
                                }
                            },
                        },
                    },
                    data: groupChangeSeries,
                    markPoint: {
                        data: [],
                    },
                },
            ],
        };
    }, [props.data, props.currency]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>PNL</span>
            </div>
            {props.isLoading ? (
                <CustomSkeleton rows={7} />
            ) : props.data.length > 0
                ? (
                    <ReactEChartsCore
                        echarts={echarts}
                        option={options}
                        showLoading={props.isLoading}
                        notMerge={true}
                        style={{ minHeight: 400 }}
                    />
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default PnlChart;