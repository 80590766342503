import { useEffect, useState } from "react";
import { ClientAnalysisTradingTypeByUserData } from "@/constants/type";
import { isEmptyOrNull } from "@/utils/string";
import { plainAxiosInstance } from "@/services/axiosSetup";
import { APIs } from "@/services/apis";
import { ErrorCatchValidator, ErrorMessageHandler } from "@/utils/Common";
import { SUCCESS_FAILED } from "@/constants";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import { PieChart } from "echarts/charts";
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    DatasetComponent,
    LegendComponent,
    ToolboxComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import CustomSkeleton from "@/components/Common/Skeleton";
import EmptyData from "@/components/Common/Empty";

echarts.use([
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    GridComponent,
    DatasetComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);

interface TradingTypePieProps {
    server?: string;
    login?: number | string;
    isInitialLoading: boolean;
};

const TradingTypePie = (props: TradingTypePieProps) => {
    const [data, setData] = useState<ClientAnalysisTradingTypeByUserData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(props.isInitialLoading);

    const options: any = {
        // title: {
        //     text: "Trading Type",
        // },
        tooltip: {
            trigger: "item",
        },
        toolbox: {
            show: true,
            itemSize: 20,
            feature: {
                dataView: { show: true, readOnly: false },
                saveAsImage: {
                    show: true,
                    title: "Save Image",
                },
            },
        },
        series: [
            {
                name: "Ticket Count",
                type: "pie",
                radius: "55%",
                center: ["50%", "50%"],
                label: {
                    show: true,
                    formatter: "{b}: {c} ({d}%)",
                },
                labelLine: {
                    show: true,
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: "rgba(0, 0, 0, 0.5)",
                },
                data: data.map((item: ClientAnalysisTradingTypeByUserData) => ({
                    name: item.name,
                    value: item.count,
                })),
            },
        ],
    };

    useEffect(() => {
        setIsLoading(true);
        const formData = new FormData();
        if (props && !isEmptyOrNull(props.login) && !isEmptyOrNull(props.server)) {
            formData.append("oldServer", props.server as string);
            formData.append("login", props.login ? props.login.toString() : "");
            plainAxiosInstance
                .post(`${APIs.RC_CLIENT_ANALYSIS.POST_TRADING_TYPES_BY_USER}`, formData)
                .then((res: any) => {
                    setData(res.data.length > 0 ? res.data : []);
                })
                .catch((error: any) => ErrorCatchValidator(error, (err: any) => {
                    ErrorMessageHandler("trading type chart", SUCCESS_FAILED.FAILED_LOAD_DATA, err);
                    setData([]);
                }))
                .finally(() => setIsLoading(false));
        } else setIsLoading(false);
        return () => {
            setIsLoading(false);
        };
    }, [props.server, props.login]);

    return (
        <div className="client-analysis-chart-panel">
            <div className="title-box">
                <span className={`title-box-text`}>Trading Type</span>
            </div>
            {isLoading ? (
                <CustomSkeleton rows={7} />
            ) : data.length > 0
                ? (
                    <ReactEChartsCore
                        echarts={echarts}
                        option={options}
                        showLoading={isLoading}
                        notMerge={true}
                        style={{ minHeight: 400 }}
                    />
                ) : (
                    <>
                        <EmptyData />
                    </>
                )}
        </div>
    );
};

export default TradingTypePie;